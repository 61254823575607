/* eslint-disable import/prefer-default-export */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react';
import { CookiesProvider } from 'react-cookie';
import './src/styles/global.scss';

export const wrapRootElement = ({ element }) => (
  <CookiesProvider>{element}</CookiesProvider>
);
